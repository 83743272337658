<template>
  <section class="message task-created">
    <section>
      <hub-icon name="email" size="xlg" />
      <div>
        <h4>{{ metadata.subject }}</h4>
        <div>
          <em><label>Sender</label>: {{ metadata.sender }}</em>
        </div>
      </div>
      <div class="metadata">
        <div>
          {{ new Date(metadata.receivedDateTime).toLocaleString() }}
        </div>
      </div>
      <div>
        <hub-button variant="icon" @click="download"><hub-icon name="download"></hub-icon></hub-button>
      </div>
    </section>
  </section>
</template>

<script>
import Button from './../../common/Button';
import Icon from './../../common/Icon';
import httpClient from '@/utils/httpClient';

export default {
  components: {
    'hub-button': Button,
    'hub-icon': Icon
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    createdBy: {
      type: String,
      required: true
    },
    createdAt: {
      type: Date,
      required: true
    },
    inventionId: {
      type: String,
      required: true
    },
    metadata: {
      type: Object,
      default: () => null
    }
  },
  methods: {
    async download() {
      const fileToken = await httpClient.post('/api/hub-correspondence/token', {
        path: `/correspondence/${this.inventionId}/messages/${this.metadata.hash}/download`
      });

      const url = `/api/hub-correspondence/correspondence/${this.inventionId}/messages/${this.metadata.hash}/download?authToken=${fileToken}`;
      window.open(url, '_blank');
    }
  }
};
</script>

<style lang="scss" scoped>
.message.task-created {
  display: grid;
  grid-template-rows: minmax(0, 1fr);
  grid-template-columns: minmax(0, 1fr);
  height: 100%;
  width: 100%;
  background-color: var(--theme-surface);
  border-radius: 3px;
  padding: 0.75rem 1rem 0.75rem 1.25rem;
  grid-gap: 0.25rem;

  label {
    font-weight: 600;
  }

  em {
    font-size: 0.65rem;
    font-style: italic;
    color: var(--theme-on-background-accent);
  }

  > section {
    display: grid;
    grid-template-columns: 50px minmax(0, 1fr) max-content max-content;
    grid-template-rows: max-content;
    grid-gap: 0.5rem;
    align-items: center;

    h4 {
      font-weight: 700;
    }
  }

  .metadata {
    text-align: right;
    font-size: 0.65rem;
  }

  i {
    cursor: pointer;
  }
}
</style>
